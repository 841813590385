<template id="chart">
    <div class="text-center">
        <h3 class="text-warning mt-2" v-if="series.length == 0">
            Ni podatkov za prikaz
        </h3>
        <div v-if="series && series.length != 0" class="flex justify-center">
            <VueApexCharts type="donut" width="300" stroke="35" class="d-flex justify-content-start" :options="chartOptions" :series="series"/>
        </div>
    </div>
</template>
<script>
    import VueApexCharts from 'vue-apexcharts'
    export default {
        components: { VueApexCharts },
        props: {
            options: {
                type: Object
            },
            series: {
                type: Array
            }
        },
        data() {
            return {
                neki: 10000000000000000,
                chartOptions: {
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        show: false
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                size: '90',
                                background: '#fff',
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                        fontWeight: 600,
                                        offsetY: -30
                                    },
                                    total: {
                                        show: true,
                                        showAlways: true,
                                        label: 'Skupaj',
                                        fontWeight: 'bold',
                                        fontSize: '20px',
                                        formatter: (w) => {
                                            const totalTmp = w.globals.seriesTotals.reduce((a, b) => {
                                                return a + b
                                            }, 0)
                                            return totalTmp.toLocaleString(undefined, {minimumFractionDigits: 2})
                                        }
                                    },
                                    value: {
                                        show: true,
                                        fontWeight: 'bolder',
                                        fontSize: (this.series.reduce((a, b) => a + b, 0) < 1000) ? '55px' : ((this.series.reduce((a, b) => a + b, 0) < 10000 ? '45px' : '35px')),
                                        offsetY: 10
                                    }
                                }
                            }
                        }
                    },
                    tooltip: {
                        marker: {
                            show: true
                        },
                        y: {
                            formatter(val) {
                                return `${val.toLocaleString(undefined, {minimumFractionDigits: 2})  } €`
                            }
                        },
                        x: {
                            show: false
                        }
                    },
                    stroke: {
                        show: false  
                    }
                    // responsive: [
                    //     {
                    //         breakpoint: 1300,
                    //         options: {
                    //             plotOptions: {
                    //                 pie: {
                    //                     donut: {
                    //                         labels: {
                    //                             total: {
                    //                                 fontSize: '16px'
                    //                             },
                    //                             value: {
                    //                                 fontSize: '28px',
                    //                                 offsetY: 5
                    //                             }
                    //                         }
                    //                     }
                    //                 }
                    //             }
                    //         }
                    //     },
                    //     {
                    //         breakpoint: 1100,
                    //         options: {
                    //             plotOptions: {
                    //                 pie: {
                    //                     donut: {
                    //                         labels: {
                    //                             total: {
                    //                                 fontSize: '12px'
                    //                             },
                    //                             value: {
                    //                                 fontSize: '20px',
                    //                                 offsetY: -3
                    //                             }
                    //                         }
                    //                     }
                    //                 }
                    //             }
                    //         }
                    //     }
                    // ]
                }
            }
        },
        created() {
            this.chartOptions = Object.assign({}, this.chartOptions, this.options)
        }
    }
</script>

<style>
</style>